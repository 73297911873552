(function($) {
    $(document).foundation();
    
    $(function () {

        $('select.news-mobiletabs').on('change', function() {
            var activeAttri = this.value;
            $('.tabs-content .content').removeClass('active');
            $("#"+activeAttri).addClass('active');

        });

        // Select all links with hashes
        $('a[href*="#"]')
        // Remove links that don't actually link to anything
        .not('[role="tab"]')
        .not('[id="loadMore"]')
        .not('[data-vc-tabs=""]')
        .not('[href="#0"]')
        .click(function(event) {
        // On-page links
        if (
            location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') 
            && 
            location.hostname === this.hostname
        ) {
            // Figure out element to scroll to
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            // Does a scroll target exist?
            if (target.length) {
            // Only prevent default if animation is actually gonna happen
            event.preventDefault();
            $('html, body').animate({
                scrollTop: target.offset().top
            }, 1000, function() {
                // Callback after animation
                // Must change focus!
                var $target = $(target);
                $target.focus();
                if ($target.is(":focus")) { // Checking if the target was focused
                return false;
                } else {
                $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
                };
            });
            }
        }
        });

            
        var mywindow = $(window);
        var mypos = mywindow.scrollTop();
        var headerH = $('#header').height();
        var filterformH = $('#filterform').height();
        $('.filter-fixed-s').css('top',headerH);
        $('.filter-fixed-s').hide();
        mywindow.scroll(function() {
            if (mypos > headerH + filterformH) {
                if(mywindow.scrollTop() > mypos) {
                    //alert('up');
                    $('.filter-fixed-s').fadeOut();
                } else {
                    $('.filter-fixed-s').fadeIn();
                    //alert('down');
                }
            }else{
                $('.filter-fixed-s').fadeOut();
            }
            mypos = mywindow.scrollTop();
        }); 

        $(".tendering-content").slice(0, 9).show();

        if ($(".tendering-content").length > 9) {
            $("#loadMore").on('click', function (e) {
                e.preventDefault();
                $(".tendering-content:hidden").slice(0, 9).slideDown();
                if ($(".tendering-content:hidden").length === 0) {
                    $("#loadMore").fadeOut('slow');
                }
                $('html,body').animate({
                    scrollTop: $(this).offset().top
                }, 1500);
            });
        }else{
            $("#loadMore").fadeOut('slow');
        }
    });

    //$( "li.has-dropdown > a" ).append( ">" );


    $('.toggle-topbar').click(function(){
        $("#header").toggleClass("nav-expanded");
        $('.dropdown li.title h5 a').text('Menu');
        $(".search-wrapper").css( { left : "100%"} );
    });
    
    $( document ).ready(function() {
            if ($(window).width() < 945) {
            $('.search-item').click(function(){
                $(".top-bar-section").css( { left : "-100%"} );
                $(".search-wrapper").css( { left : "0"} );
            });
            }
    }); 
     
	$('.top-bar .top-bar-section .menu-item').each(function(){
		if($(this).text() === $('#active-menu').attr('data-active')){
			$(this).addClass('active');
		}
	});

	$('.tooltipster').tooltipster({
	    trigger: 'click'
    });
    
    // careers

    $('.file1').click(function(){
        $('#UploadCurriculumVitae').trigger('click'); 
    });
    $('#UploadCurriculumVitae').change(function(e){
        var fileName = e.target.files[0].name;
        $('.file1').text(fileName);
    });

    $('.file2').click(function(){
        $('#UploadCoverLetter').trigger('click'); 
    });
    $('#UploadCoverLetter').change(function(e){
        var fileName = e.target.files[0].name;
        $('.file2').text(fileName);
    });

    // end careers

	$('.home-slider').owlCarousel({
	    items: 1,
	    margin: 0,
			nav:true,
			loop: true,
			navText: ['<i class="fa fa-chevron-left" aria-hidden="true"></i>','<i class="fa fa-chevron-right" aria-hidden="true"></i>'],
			autoplay: true,
			autoplayTimeout: 8000
	});

	var $sync1 = $("#sync1"),
    $sync2 = $("#sync2"),
    flag = false,
    duration = 300;

	$sync1
    .owlCarousel({
        items: 1,
        margin: 10,
        nav: false,
        dots: false,
        autoHeight: true
    })
    .on('change.owl.carousel', function (e) {
        if (e.namespace && e.property.name === 'items' && !flag) {
            flag = true;
            $sync2.trigger('to.owl.carousel', [e.item.index, duration, true]);
            flag = false;
        }
    });

	$sync2
    .owlCarousel({
        margin: 10,
        items: 4,
        nav: true,
        dots: false
    })
    .on('click', '.owl-item', function () {
        $sync1.trigger('to.owl.carousel', [$(this).index(), duration, true]);
    })
    .on('change.owl.carousel', function (e) {

        if (e.namespace && e.property.name === 'items' && !flag) {
            flag = true;
            $sync1.trigger('to.owl.carousel', [e.item.index, duration, true]);
            flag = false;
        }
    });

    $('.toggle-reports').click(function(e){
    	e.preventDefault();
    	$('#other-reports').show();
    	$(this).parents('.show-more').hide();
    });

		$("img.lazy").lazyload({
			effect : "fadeIn"
		});

		$('.top-bar .icon-search').click(function() {
			$('.search-wrapper').show();
			$('.search-wrapper input').focus();
		});

		// $('.search-wrapper').focusout(function(){
		// 	$(this).hide();
		// });
		$(window).resize(function(){
			$('.search-wrapper').hide();
		});

		$(".datepicker").datepicker({
			dateFormat: 'yy-mm-dd'
		});

		$.fn.serializeFormJSON = function () {
      var o = {};
      var a = this.serializeArray();
      $.each(a, function () {
          if (o[this.name]) {
              if (!o[this.name].push) {
                  o[this.name] = [o[this.name]];
              }
              o[this.name].push(this.value || '');
          } else {
              o[this.name] = this.value || '';
          }
      });
      return o;
    };

		$('.select2').select2({
		  minimumResultsForSearch: Infinity
		});
})(jQuery);
